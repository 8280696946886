import { render, staticRenderFns } from "./Toggle.vue?vue&type=template&id=6b21ce04&scoped=true"
import script from "./Toggle.vue?vue&type=script&lang=ts"
export * from "./Toggle.vue?vue&type=script&lang=ts"
import style0 from "./Toggle.vue?vue&type=style&index=0&id=6b21ce04&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b21ce04",
  null
  
)

export default component.exports