




















































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { mapGetters } from 'vuex'
import { Navigation, Project, User } from '~/models'
import { navigation, showNavOption } from '~/utils/navigationAuth'

@Component({
  computed: {
    ...mapGetters('user', {
      user: 'user',
      project: 'project',
      isAdmin: 'isAdmin',
      isGuest: 'isGuest',
      isDeveloper: 'isDeveloper',
      isBuilder: 'isBuilder',
    }),
  },
})
export default class Sidenav extends Vue {
  user: User
  isDeveloper: boolean
  isAdmin: boolean
  project: Project
  isGuest: boolean
  isBuilder: boolean
  items: Array<Navigation> = navigation
  loading: boolean = true

  @Prop() showNav: boolean = false

  // Watchers to detect changes in Vuex properties
  watch: {
    user: 'checkInitialization'
    isAdmin: 'checkInitialization'
    project: 'checkInitialization'
  }

  mounted() {
    this.checkInitialization()
  }

  checkInitialization() {
    if (this.user && this.project && this.isAdmin !== undefined) {
      this.loading = false
    }
  }

  showOption(item: any): boolean {
    return showNavOption(
      item,
      this.project,
      this.isDeveloper,
      this.user,
      this.isGuest,
      this.isAdmin,
      this.isBuilder
    )
  }

  @Emit()
  toggleNav() {
    return false
  }
}
