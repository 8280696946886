import { $axios } from '~/utils/api'
import Plan from '~/models/plan'
import Addition from '~/models/addition'
import {
  ElevationForm,
  AdditionForm,
  ReviewElevationForm,
} from '~/models/forms'
import { PagedResponse } from '~/models'

const END_POINT = '/plans'

const getPlans = (
  queryFilters?: string,
  page?: any
): Promise<PagedResponse<Plan>> => {
  let url = `${END_POINT}?${queryFilters ? `${queryFilters}` : ''}`
  if (page) {
    url = `${url}&page=${page}`
  }

  return $axios.$get(url)
}

const planSearch = (keyword: string): Promise<PagedResponse<Plan>> =>
  $axios.$get(`${END_POINT}?plan_search=${keyword}`)

const getPlan = (slug: String): Promise<Plan> =>
  $axios.$get(`${END_POINT}/${slug}`)

const addPlan = (form: any): Promise<Plan> =>
  $axios.$post(`${END_POINT}/modify`, form)

const editPlan = (form: any, id: number): Promise<any> =>
  $axios.$patch(`${END_POINT}/modify/${id}`, form)

const getPlanFormFieldsData = () =>
  $axios.get(`${END_POINT}/modify/get_fields_options`)

const getPlanAdditions = (slug: string): Promise<Addition[]> =>
  $axios.$get(`${END_POINT}/${slug}/additions/v2`)

const addAdition = (form: AdditionForm) =>
  $axios.$post(`${END_POINT}/additions`, form)

const editAddition = (id: number, form: AdditionForm): Promise<Addition> =>
  $axios.patch(`${END_POINT}/additions/${id}`, form)

const getAddition = (id: number) => $axios.$get(`${END_POINT}/additions/${id}`)

const getDefaulFormFields = () =>
  $axios.$get(`${END_POINT}/additions/get_fields_options`)

const getElevationDefaultFormFields = () =>
  $axios.$get(`${END_POINT}/elevation/get_fields_options`)

const addElevation = (form: ElevationForm) =>
  $axios.$post(`${END_POINT}/elevation`, form)

const getElevation = (id: string) => $axios.$get(`${END_POINT}/elevation/${id}`)

const editElevation = (id: number, form: ElevationForm) =>
  $axios.$patch(`${END_POINT}/elevation/${id}`, form)

const reviewElevation = (id: number, form: ReviewElevationForm) =>
  $axios.$post(`${END_POINT}/elevation/${id}/review`, form)

const getPlanByName = (name: string) => $axios.$get(`${END_POINT}?name=${name}`)

const getSpecialFeatures = () =>
  $axios.get(`${END_POINT}/elevation/get_special_features`)

const getElevationMatrixOptions = (id: number | string) =>
  $axios.get(`${END_POINT}/elevation/${id}/elevation_matrix_options`)

const reviewBulk = (form: any, id: number): Promise<any> =>
  $axios.$post(`${END_POINT}/modify/review_bulk_plan`, form)

const getPublicPlans = (
  lotId: number = null,
  pageSize: string | number = 'max',
  available?: boolean
) => {
  let url = `${END_POINT}?page_size=${pageSize}`

  if (lotId) {
    url += `&lot=${lotId}`
  }

  if (available !== undefined) {
    url += `&available=${available}`
  }
  return $axios.get(url)
}

const listPlansSummary = (
  groupBy: string = 'builder',
  slug: string = '',
  params: any = null
) => {
  let url = `v2/plans?group_by=${groupBy}`
  if (slug) {
    url += `&project=${slug}`
  }
  if (params) {
    url += parsePlanParams(params)
  }
  return $axios.get(url)
}

const parsePlanParams = (params: any): string => {
  let query = ''
  if (params.beds) query += `&beds=${params.beds}`
  if (params.baths) query += `&baths=${params.baths}`
  if (params.stories) query += `&stories=${params.stories}`
  if (params.garages) query += `&garages=${params.garages}`
  if (params.sqft_min) query += `&sqft_min=${params.sqft_min}`
  if (params.sqft_max) query += `&sqft_max=${params.sqft_max}`
  if (params.price_min) query += `&price_min=${params.price_min}`
  if (params.price_max) query += `&price_max=${params.price_max}`
  if (params.builder_id) {
    const builder = params.builder_id.reduce(
      (tally: string, builder: number, i: number) => {
        if (i) tally += ','
        return (tally += builder)
      },
      ''
    )
    query += `&builder_id=${builder}`
  }
  if (params.style) {
    const style = params.style.reduce(
      (tally: string, style: number, i: number) => {
        if (i) tally += ','
        return (tally += style)
      },
      ''
    )

    query += `&style=${style}`
  }

  if (params.neighborhood) {
    const neighborhood = params.neighborhood.reduce(
      (tally: string, style: number, i: number) => {
        if (i) tally += ','
        return (tally += style)
      },
      ''
    )

    query += `&neighborhood=${neighborhood}`
  }

  if (params.acreage) {
    const acreage = params.acreage.reduce(
      (tally: string, price: number[], i: number) => {
        if (i) tally += ','
        return (tally += JSON.stringify(price))
      },
      ''
    )

    query += `&acreage=${acreage}`
  }

  return query
}

const planDetails = (planId: number) => $axios.get(`v2/plans/${planId}`)

export {
  getPlans,
  getPlan,
  addPlan,
  editPlan,
  getPlanFormFieldsData,
  getPlanAdditions,
  addAdition,
  getAddition,
  getDefaulFormFields,
  getElevationDefaultFormFields,
  addElevation,
  editElevation,
  editAddition,
  getElevation,
  reviewElevation,
  planSearch,
  getPlanByName,
  getSpecialFeatures,
  getElevationMatrixOptions,
  getPublicPlans,
  listPlansSummary,
  planDetails,
  reviewBulk,
}
