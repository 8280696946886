import { RoleTypes } from '~/enums/users'
import { Navigation, User } from '~/models'
import Project from '~/models/project'

const ACCESS_DISABLED_ROUTE: string = '/access_disabled'

enum NavigationSections {
  DASHBOARD = 'dashboard',
  XO_MAP = 'xo_map',
  ARCHITECTURAL_SUBMISSION = 'architectural_submission',
  MASTER_PLANS = 'master_plans',
  REPORTS = 'reports',
  PARCEL_IQ = 'parcel_iq',
  NEIGBORS_EDITOR = 'neigbors_editor',
  MISC_SUBMITTALS = 'misc_submittals',
  CONTRACTS = 'contracts',
}

const navigation: Array<Navigation> = [
  {
    id: NavigationSections.DASHBOARD,
    name: 'Dashboard',
    icon: 'dashboard',
    path: '/',
    enabledConfigKey: 'dashboard_enabled',
  },
  {
    id: NavigationSections.XO_MAP,
    name: 'Lots & Homes',
    icon: 'navigate',
    path: '/lots',
    enabledConfigKey: 'lot_home_sales_map_enabled',
  },
  {
    id: NavigationSections.ARCHITECTURAL_SUBMISSION,
    name: 'Arch. Submittals',
    icon: 'brickWall',
    path: '/architectural_submissions',
    enabledConfigKey: 'arch_submissions_enabled',
  },
  {
    id: NavigationSections.MASTER_PLANS,
    name: 'Master Plans',
    icon: 'home',
    path: '/plans',
    enabledConfigKey: 'master_plans_enabled',
  },
  {
    id: NavigationSections.REPORTS,
    name: 'Reports',
    icon: 'artboard',
    path: '/reports',
    enabledConfigKey: 'reports_enabled',
  },
  {
    id: NavigationSections.PARCEL_IQ,
    name: 'Parcel IQ',
    icon: 'mapOutlined',
    path: '/parcel',
    enabledConfigKey: 'parcel_iq_enabled',
  },
  {
    id: NavigationSections.NEIGBORS_EDITOR,
    name: 'Neighbor Editor',
    icon: 'neighborhood',
    path: '/neighbors/editor',
    enabledConfigKey: '',
    allowedFor: RoleTypes.ADMIN,
  },
  {
    id: NavigationSections.MISC_SUBMITTALS,
    name: 'Misc. Submittals',
    icon: 'neighborhood',
    path: '/misc',
    enabledConfigKey: '',
  },
  {
    id: NavigationSections.CONTRACTS,
    name: 'Contracts',
    icon: 'neighborhood',
    path: '/contracts',
    enabledConfigKey: 'contracts_enabled',
  },
]

const showNavOption = (
  item: any,
  project: Project,
  isDeveloper: boolean,
  user: User,
  isGuest: boolean,
  isAdmin: boolean,
  isBuilder: boolean
): boolean => {
  if (!project || !project.config) {
    return false
  }

  const specialTabs: Array<NavigationSections> = [
    NavigationSections.DASHBOARD,
    NavigationSections.REPORTS,
    NavigationSections.PARCEL_IQ,
  ]

  if (item.id === NavigationSections.PARCEL_IQ) {
    if (isGuest || isBuilder) {
      return false
    }

    return project.config.parcel_iq_enabled
  }

  if (user.guest_reviewer && specialTabs.includes(item.id)) {
    return false
  }

  if (item.id === NavigationSections.DASHBOARD) {
    return project?.config?.dashboard_enabled
  }

  if (isDeveloper && specialTabs.includes(item.id)) {
    return true
  }

  if (item.id === NavigationSections.MISC_SUBMITTALS) {
    return project.config.misc_submittal_enabled
  }

  if (project.config[item.enabledConfigKey] === false) {
    return false
  }

  if (
    item.enabledConfigKey === 'parcel_iq_enabled' &&
    (!isDeveloper || isGuest)
  ) {
    return false
  }

  if (!isAdmin && item?.allowedFor === RoleTypes.ADMIN) {
    return false
  }

  return true
}

export { navigation, ACCESS_DISABLED_ROUTE, NavigationSections, showNavOption }
