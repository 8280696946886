import { User } from '~/models'
import { $axios } from '~/utils/api'

const USERS_ENDPOINT = '/users'

const toggleEmailNotifications = (formData: any): Promise<User> =>
  $axios.$patch(`${USERS_ENDPOINT}/email_notifications`, formData)

const getCurrentUser = (): Promise<User> =>
  $axios.$get(`${USERS_ENDPOINT}/get_current`)

const setNotificationTime = (notification_datetime: any) =>
  $axios.$patch(`${USERS_ENDPOINT}/notification`, notification_datetime)

export { toggleEmailNotifications, getCurrentUser, setNotificationTime }
