import { FilterFields } from '~/models/filters'
import { $axios } from '~/utils/api'

const FILTERS_ENDPOINT = '/filters'

const getFilters = (
  name: string,
  builder?: string
): Promise<FilterFields[]> => {
  let url: string = `${FILTERS_ENDPOINT}/${name}`
  if (builder) {
    url = `${url}?builder=${builder}`
  }

  return $axios.$get(url)
}

export { getFilters }
