var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"button",attrs:{"type":"button","aria-pressed":"false"},on:{"click":_vm.handleClick}},[_c('span',{staticClass:"w-full h-full rounded-md",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',{class:[
      'h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200',
      {
        'bg-gray-200': !_vm.isEnabled,
        'bg-blue-500': _vm.isEnabled,
      } ],attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',{class:[
      "left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200",
      {
        'translate-x-0': !_vm.isEnabled,
        'translate-x-5': _vm.isEnabled,
      } ],attrs:{"aria-hidden":"true"}})])}
var staticRenderFns = []

export { render, staticRenderFns }