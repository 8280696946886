



























































































































































































































































































































































































import { Component, Prop, Watch, mixins, Emit } from 'nuxt-property-decorator'
import { mapState, mapGetters } from 'vuex'
import PropertiesCounter from '../sales_map_v3/PropertiesCounter.vue'
import PlansCounter from './PlansCounter.vue'
import MapSelect from '~/components/consumer_map/mapSelect.vue'
import IconButton from '~/components/shared/buttons/IconButton.vue'
import BedroomButtonGroup from '~/components/sales_map_v3/BedroomButtonGroup.vue'
import BedsBathsFilter from '~/components/sales_map_v3/BedsBathsFilter.vue'
import OtherFilters from '~/components/consumer_map_v3/OtherFilters.vue'
import SqftFilter from '~/components/sales_map_v3/SqftFilter.vue'
import PriceFilter from '~/components/consumer_map_v3/PriceFilter.vue'
import MapSearchButton from '~/components/consumer_map/MapSearchButton.vue'
import FiltersBox from '~/components/sales_map_v3/FiltersBox.vue'
import { getConsumerMapFilter } from '~/api/consumer_map.api'
import { FilterFields } from '~/models/filters'
import globalStyling from '~/mixins/globalStyling'
import { getLocalStorageItem, storageAvailable } from '~/utils/localStorage'
import { favoritesStore } from '~/store'
import { ConsumerMapRoutes } from '~/enums/consumerMap'
import SeasonPicker from '~/components/sales_map_v3/SeasonPicker.vue'
import { Project } from '~/models'
import AvailableDateFilter from '~/components/shared/AvailableDateFilter.vue'
import { Events } from '~/utils/eventLayers'
import CustomFilters from '~/components/consumer_map_v3/CustomFilters.vue'
import { sortOptions } from '~/constants/sortOptions'

@Component({
  components: {
    MapSelect,
    IconButton,
    BedroomButtonGroup,
    MapSearchButton,
    FiltersBox,
    BedsBathsFilter,
    OtherFilters,
    SqftFilter,
    PriceFilter,
    PropertiesCounter,
    PlansCounter,
    SeasonPicker,
    AvailableDateFilter,
    CustomFilters,
  },
  mixins: [globalStyling],
  computed: {
    ...mapState('consumer-map', {
      currentProject: 'currentProject',
    }),
    ...mapGetters('consumer-map', {
      isConsumerMapPlansDisabled: 'isConsumerMapPlansDisabled',
      isMapHiddenConsumerMapV3: 'isMapHiddenConsumerMapV3',
    }),
  },
})
export default class MapFiltersV3 extends mixins(globalStyling) {
  @Prop({ default: false }) mFilters: boolean

  isConsumerMapPlansDisabled: string
  isMobile: boolean = window.innerWidth <= 1024

  filtersVisibilitySettings: {
    price: boolean
    bedrooms: boolean
    builder: boolean
    architecture: boolean
    available: boolean
    neighborhood: boolean
    propertyType: boolean
  } = {
    price: true,
    bedrooms: true,
    builder: true,
    architecture: true,
    available: true,
    neighborhood: true,
    propertyType: true,
  }

  pagesVisibilitySettings: {
    map: boolean
    plans: boolean
    favorites: boolean
  } = {
    map: true,
    plans: true,
    favorites: true,
  }

  prices: FilterFields[]
  builders: FilterFields[]
  styles: FilterFields[]
  beds: FilterFields[]
  openedMenu: string = ''
  searchActive: boolean = false
  priceOptions: any[] = []
  bedroomOptions: any[] = []
  builderOptions: any[] = []
  architectureOptions: any[] = []
  selectedPriceOptions: any[] = []
  selectedBedRooms: any = null
  selectedBuilderOptions: any[] = []
  selectedArchOptions: any[] = []
  selectedCustomFilterOptions: any[] = []
  isShareWithMeVisible: boolean = false
  bathsOptions: any[] = []
  garagesOptions: any[] = []
  storiesOptions: any[] = []
  selectedBaths: any = null
  selectedGarages: any = null
  selectedStories: any = null
  neighborhoodOptions: any[] = []
  selectedNeighborhood: any[] = []
  sqftMin: any = null
  sqftMax: any = null
  priceMin: any = null
  priceMax: any = null
  minimumAvailablePrice: number = 100000
  maximumAvailablePrice: number = 1000000
  minimumAvailableSqft: number = 1000
  maximumAvailableSqft: number = 5000
  currentProject: Project
  moveInDate: any = null
  customFilters: any = null
  isMapHiddenConsumerMapV3: boolean
  sortOptions = sortOptions
  selectedSortOption: any[] = []
  propertyTypeOptions: any[] = []
  selectedPropertyTypeOptions: any[] = []
  condoAt: any = null

  created() {
    this.customFilters = this.customFiltersFetch(this.$route.params.slug)
  }

  customFiltersFetch(projectSlug: string) {
    try {
      const filters = require(`~/assets/customFilters/${projectSlug}.json`)
      return filters
    } catch (error) {
      return null
    }
  }

  mounted() {
    this.$nuxt.$on('CondoClicked', this.handleCondoClicked)
    window.addEventListener('resize', this.checkIsMobile)
  }

  beforeDestroy() {
    this.$nuxt.$off('CondoClicked', this.handleCondoClicked)
    window.removeEventListener('resize', this.checkIsMobile)
  }

  handleCondoClicked(lotInfo: any, callback: Function) {
    const filterValue = lotInfo.building_tooltip_title
    const condoAt = {
      value: filterValue,
      label: `${
        this.getStyleVariableValue('--v3-cm-filter-condo-label')?.replace(
          /['"]+/g,
          ''
        ) || 'Condos'
      } at ${filterValue}`,
      type: 'condo_at',
    }
    this.condoAt = condoAt

    if (callback) {
      callback()
    }
  }

  get isStorageEnabled(): boolean {
    return storageAvailable()
  }

  get seasonEnabled() {
    return !!this.currentProject?.config?.enable_season
  }

  get favCount() {
    return favoritesStore.favCount
  }

  get isFavoritesOrSharedRoute(): boolean {
    return (
      this.getActiveRoute() === ConsumerMapRoutes.FAVORITES ||
      this.getActiveRoute() === ConsumerMapRoutes.SHARED
    )
  }

  get isFavoritesRoute(): boolean {
    return (
      this.getActiveRoute() === ConsumerMapRoutes.FAVORITES &&
      !this.isShareWithMeVisible
    )
  }

  get isPlanRoute(): boolean {
    return this.getActiveRoute() === ConsumerMapRoutes.PLANS
  }

  async loadProjectsSpecificStyle(projectId: string) {
    await import(`assets/css/${projectId}.css`)
  }

  @Watch('currentProject', { deep: true })
  onCurrentProjectChanged(): void {
    this.init()
  }

  @Emit('seasonChange')
  seasonChange(value: boolean): boolean {
    return value
  }

  navigationRules() {
    switch (this.$route.name) {
      case 'v3-map-slug': {
        if (!this.isVisible('--v3-cm-page-map')) {
          this.$router.push('/unauthorized')
        }
        break
      }
      case 'v3-map-slug-plans': {
        if (!this.isVisible('--v3-cm-page-plans')) {
          this.$router.push('/unauthorized')
        }
        break
      }
    }
  }

  async initFilters(): Promise<void> {
    this.minimumAvailablePrice =
      this.currentProject?.config?.min_price || this.minimumAvailablePrice
    this.maximumAvailablePrice =
      this.currentProject?.config?.max_price || this.maximumAvailablePrice
    this.minimumAvailableSqft =
      this.currentProject?.config?.min_sqft || this.minimumAvailableSqft
    this.maximumAvailableSqft =
      this.currentProject?.config?.max_sqft || this.maximumAvailableSqft

    const projectSlug: string = this.currentProject.slug
    await this.loadProjectsSpecificStyle(projectSlug)

    this.navigationRules()

    this.filtersVisibilitySettings = this.getFiltersVisibilitySettings()
    this.pagesVisibilitySettings = this.getPagesVisibilitySettings()

    const filters = await Promise.all([
      getConsumerMapFilter('prices', projectSlug),
      getConsumerMapFilter('bedrooms', projectSlug),
      getConsumerMapFilter('baths', projectSlug),
      getConsumerMapFilter('builders', projectSlug),
      getConsumerMapFilter('architectures', projectSlug),
      getConsumerMapFilter('neighborhood', projectSlug),
      getConsumerMapFilter('garages', projectSlug),
      getConsumerMapFilter('stories', projectSlug),
      getConsumerMapFilter('lot_property_types', projectSlug),
    ])

    this.priceOptions = filters[0].map((filter) => ({
      ...filter,
      type: 'price',
    }))
    this.bedroomOptions = filters[1].map((filter) => ({
      ...filter,
      type: 'bedrooms',
    }))
    this.bathsOptions = filters[2].map((filter) => ({
      ...filter,
      type: 'baths',
    }))
    this.builderOptions = filters[3].map((filter) => ({
      ...filter,
      type: 'builder',
    }))
    this.architectureOptions = filters[4].map((filter) => ({
      ...filter,
      type: 'arch',
    }))
    this.neighborhoodOptions = filters[5].map((filter) => ({
      ...filter,
      type: 'neighborhood',
    }))
    this.garagesOptions = filters[6].map((filter) => ({
      ...filter,
      type: 'garages',
    }))
    this.storiesOptions = filters[7].map((filter) => ({
      ...filter,
      type: 'stories',
    }))
    this.propertyTypeOptions = filters[8].map((filter) => ({
      ...filter,
      type: 'property_type',
    }))
  }

  async init(): Promise<void> {
    await this.initFilters()
    const queries = { ...this.$route.query }
    if (queries.price) {
      const queryPrice = queries.price
      if (Array.isArray(queryPrice)) {
        this.selectedPriceOptions = queryPrice.map((prices: any) =>
          this.priceOptions.find(({ value }) => prices === value)
        )
      } else {
        this.selectedPriceOptions = [
          this.priceOptions.find(({ value }) => queryPrice === value),
        ]
      }
      delete queries.price
    }

    if (queries.builder || queries.builder_id) {
      const queryBuilder = queries.builder || queries.builder_id
      if (Array.isArray(queryBuilder)) {
        this.selectedBuilderOptions = queryBuilder.map((builder: string) =>
          this.builderOptions.find(({ value }) => +builder === value)
        )
      } else {
        this.selectedBuilderOptions = [
          this.builderOptions.find(({ value }) => +queryBuilder === value),
        ]
      }

      if (queries.builder) {
        delete queries.builder
      }
      if (queries.builder_id) {
        delete queries.builder_id
      }
    }

    if (queries.beds) {
      const queryBeds = queries.beds
      this.selectedBedRooms = this.bedroomOptions.find(
        ({ value }) => +queryBeds === value
      )
      if (this.selectedBedRooms) {
        this.updateRoomsFilter(this.selectedBedRooms.value)
      }
      delete queries.beds
    }

    if (queries.baths) {
      const queryBaths = queries.baths
      this.selectedBaths = this.bathsOptions.find(
        ({ value }) => +queryBaths === value
      )
      if (this.selectedBaths) {
        this.updateBathsFilter(this.selectedBaths.value)
      }
      delete queries.baths
    }

    if (queries.sort) {
      const querySort = queries.sort
      if (Array.isArray(querySort)) {
        this.selectedSortOption = querySort.map((sort: string) =>
          this.sortOptions.find(({ value }) => sort === value)
        )
      } else {
        this.selectedSortOption = [
          this.sortOptions.find(({ value }) => querySort === value),
        ]
      }
      delete queries.sort
    }

    if (queries.stories) {
      const queryStories = queries.stories
      this.selectedStories = this.storiesOptions.find(
        ({ value }) => +queryStories === value
      )
      if (this.selectedStories) {
        this.updateStoriesFilter(this.selectedStories.value)
      }
      delete queries.stories
    }

    if (queries.garages) {
      const queryGarages = queries.garages
      this.selectedGarages = this.garagesOptions.find(
        ({ value }) => +queryGarages === value
      )
      if (this.selectedGarages) {
        this.updateGaragesFilter(this.selectedGarages.value)
      }
      delete queries.garages
    }

    if (queries.style) {
      const queryStyle = queries.style
      if (Array.isArray(queryStyle)) {
        this.selectedArchOptions = queryStyle.map((style: string) =>
          this.architectureOptions.find(({ value }) => +style === value)
        )
      } else {
        this.selectedArchOptions = [
          this.architectureOptions.find(({ value }) => +queryStyle === value),
        ]
      }
      delete queries.style
    }

    if (queries.condo_at) {
      const queryCondoAt = queries.condo_at
      this.condoAt = {
        value: queryCondoAt,
        label: `${
          this.getStyleVariableValue('--v3-cm-filter-condo-label')?.replace(
            /['"]+/g,
            ''
          ) || 'Condos'
        } at ${queryCondoAt}`,
        type: 'condo_at',
      }
      delete queries.condo_at
    }

    if (queries.property_type) {
      const queryPropertyType = queries.property_type
      this.updatePropertyTypeFilter(queryPropertyType as string)
      delete queries.property_type
    }

    if (queries.neighborhood) {
      const queryNeighborhood = queries.neighborhood
      if (Array.isArray(queryNeighborhood)) {
        this.selectedNeighborhood = queryNeighborhood.map(
          (neighborhood: string) =>
            this.neighborhoodOptions.find(
              ({ value }) => +neighborhood === value
            )
        )
      } else {
        this.selectedNeighborhood = [
          this.neighborhoodOptions.find(
            ({ value }) => +queryNeighborhood === value
          ),
        ]
      }

      delete queries.neighborhood
    }

    if (queries.sqft_min) {
      const querySqftMin = queries.sqft_min
      this.updateSqftMinFilter(querySqftMin as string)
      delete queries.sqft_min
    }

    if (queries.sqft_max) {
      const querySqftMax = queries.sqft_max
      this.updateSqftMaxFilter(querySqftMax as string)
      delete queries.sqft_max
    }

    if (queries.price_min) {
      const queryPriceMin = queries.price_min
      this.updatePriceMinFilter(queryPriceMin as string)
      delete queries.price_min
    }

    if (queries.price_max) {
      const queryPriceMax = queries.price_max
      this.updatePriceMaxFilter(queryPriceMax as string)
      delete queries.price_max
    }

    if (queries.move_in_date) {
      const queryMoveInDate = queries.move_in_date
      this.updateMoveInDateFilter(queryMoveInDate as string)
      delete queries.move_in_date
    }

    // translating custom filters query params into visual selected elements and also BE payload params
    if (this.customFilters?.show) {
      Object.keys(queries).forEach((filterKeyName) => {
        const filtersDefinition = this.customFilters.controls || []

        filtersDefinition.forEach((filterObjectDefinition: any) => {
          if (!filterObjectDefinition.nested) {
            if (filterObjectDefinition.id === filterKeyName) {
              let valuesList = []
              if (!Array.isArray(queries[filterKeyName])) {
                valuesList = [queries[filterKeyName]]
              } else {
                valuesList = [...queries[filterKeyName]]
              }
              valuesList.forEach((filterValue: any) => {
                const option = filterObjectDefinition.settings.options.find(
                  (option: any) => option.value === filterValue
                )
                this.selectedCustomFilterOptions.push(option)
              })
            }
          } else {
            filterObjectDefinition.controls.forEach((nestedFilter: any) => {
              if (nestedFilter.id === filterKeyName) {
                let valuesList = []
                if (!Array.isArray(queries[filterKeyName])) {
                  valuesList = [queries[filterKeyName]]
                } else {
                  valuesList = [...queries[filterKeyName]]
                }
                valuesList.forEach((filterValue: any) => {
                  const option = nestedFilter.settings.options.find(
                    (option: any) => option.value === filterValue
                  )
                  this.selectedCustomFilterOptions.push(option)
                })
              }
            })
          }
        })
      })
    }

    // toca revisar por que los filtros no se estan iendo al BE .... rotceh

    this.isShareWithMeVisible =
      this.isStorageEnabled && !!getLocalStorageItem('shared-with-me')
    if (this.isStorageEnabled) favoritesStore.loadFavorites()
  }

  @Watch('filtersSelected')
  onUpdateRoomsFilterChange(value: []) {
    this.$emit('handleFilterChange', value)
  }

  updateRoomsFilter(rooms: number): void {
    const selectedOptions = {
      value: rooms,
      label: `Beds ${rooms}`,
      type: 'bedrooms',
    }

    this.selectedBedRooms = selectedOptions
  }

  @Watch('filtersSelected')
  onUpdateBathsFilterChange(value: []) {
    this.$emit('handleFilterChange', value)
  }

  updateBathsFilter(baths: number): void {
    const selectedOptions = {
      value: baths,
      label: `Baths ${baths}`,
      type: 'baths',
    }

    this.selectedBaths = selectedOptions
  }

  @Watch('filtersSelected')
  @Emit('handleFilterChange')
  onUpdateGaragesFilterChange(value: []) {
    return value
  }

  updateGaragesFilter(garages: number): void {
    const selectedOptions = {
      value: garages,
      label: `Garages ${garages}`,
      type: 'garages',
    }

    this.selectedGarages = selectedOptions
  }

  @Watch('filtersSelected')
  @Emit('handleFilterChange')
  onUpdateStoriesFilterChange(value: []) {
    return value
  }

  updateStoriesFilter(stories: number): void {
    const selectedOptions = {
      value: stories,
      label: `Stories ${stories}`,
      type: 'stories',
    }

    this.selectedStories = selectedOptions
  }

  updateSqftMinFilter(value: string): void {
    if (!this.isBelowZeroValue(value)) {
      const sqftMin = {
        value,
        label: `Sqft Min ${value}`,
        type: 'sqft_min',
      }
      this.sqftMin = sqftMin
    } else {
      this.sqftMin = null
    }
  }

  updateSqftMaxFilter(value: string): void {
    if (!this.isBelowZeroValue(value)) {
      const sqftMax = {
        value,
        label: `Sqft Max ${value}`,
        type: 'sqft_max',
      }
      this.sqftMax = sqftMax
    } else {
      this.sqftMax = null
    }
  }

  updatePriceMinFilter(value: string): void {
    if (
      !this.isBelowZeroValue(value) &&
      !this.isSetToMin(value, this.minimumAvailablePrice)
    ) {
      const priceMin = {
        value,
        label: `Price Min ${value}`,
        type: 'price_min',
      }
      this.priceMin = priceMin
    } else {
      this.priceMin = null
    }
  }

  updateMoveInDateFilter(value: string): void {
    const moveInDate = {
      value,
      label: `Move in Date ${value}`,
      type: 'move_in_date',
    }
    this.moveInDate = value ? moveInDate : null
  }

  updatePriceMaxFilter(value: string): void {
    if (
      !this.isBelowZeroValue(value) &&
      !this.isSetToMax(value, this.maximumAvailablePrice)
    ) {
      const priceMax = {
        value,
        label: `Price Max ${value}`,
        type: 'price_max',
      }
      this.priceMax = priceMax
    } else {
      this.priceMax = null
    }
  }

  updatePropertyTypeFilter(value: string): void {
    const propertyType = {
      value,
      label: `Property Type: ${
        value === 'Condo'
          ? this.getStyleVariableValue('--v3-cm-filter-condo-label')?.replace(
              /['"]+/g,
              ''
            ) || 'Condo'
          : value
      }`,
      type: 'property_type',
    }
    this.selectedPropertyTypeOptions = [propertyType]
  }

  isSetToMax(value: string | number, maxValue: string | number) {
    return value === maxValue
  }

  isSetToMin(value: string | number, minValue: string | number) {
    return value === minValue
  }

  isBelowZeroValue(value: string) {
    const price = parseInt(value as string)
    return isNaN(price) || price <= 0
  }

  get filtersSelected(): any[] {
    const filtersSelected: any[] = [...this.selectedPriceOptions]

    if (this.selectedBedRooms) filtersSelected.push(this.selectedBedRooms)
    if (this.selectedBaths) filtersSelected.push(this.selectedBaths)
    if (this.selectedStories) filtersSelected.push(this.selectedStories)
    if (this.selectedGarages) filtersSelected.push(this.selectedGarages)
    if (this.sqftMin) filtersSelected.push(this.sqftMin)
    if (this.sqftMax) filtersSelected.push(this.sqftMax)
    if (this.priceMin) filtersSelected.push(this.priceMin)
    if (this.priceMax) filtersSelected.push(this.priceMax)
    if (this.moveInDate) filtersSelected.push(this.moveInDate)
    if (this.condoAt) filtersSelected.push(this.condoAt)

    filtersSelected.push(
      ...this.selectedBuilderOptions,
      ...this.selectedArchOptions,
      ...this.selectedNeighborhood,
      ...this.selectedPropertyTypeOptions,
      ...this.selectedSortOption
    )

    if (this.selectedCustomFilterOptions)
      filtersSelected.push(...this.selectedCustomFilterOptions)

    return filtersSelected
  }

  handleMenuChange(menu: string) {
    this.openedMenu = menu
  }

  handleSearchButton() {
    this.searchActive = !this.searchActive
  }

  handlePriceCheck(selectedOptions: []) {
    this.selectedPriceOptions = selectedOptions
  }

  handleBuilderCheck(selectedOptions: []) {
    this.selectedBuilderOptions = selectedOptions
  }

  handleArchCheck(selectedOptions: []) {
    this.selectedArchOptions = selectedOptions
  }

  handleSortByCheck(selectedOptions: []) {
    this.selectedSortOption = selectedOptions
  }

  handleCheck(selectedOptions: []) {
    this.selectedCustomFilterOptions = selectedOptions
  }

  handleNeighborhoodCheck(selectedOptions: []) {
    this.selectedNeighborhood = selectedOptions
  }

  handlePropertyTypeCheck(selectedOptions: []) {
    const condoLabel =
      this.getStyleVariableValue('--v3-cm-filter-condo-label')?.replace(
        /['"]+/g,
        ''
      ) || 'Condo'

    this.selectedPropertyTypeOptions = selectedOptions.map((option: any) => {
      const label =
        option.label === 'Condo'
          ? `Property Type: ${condoLabel}`
          : `Property Type: ${option.label}`
      return {
        ...option,
        label,
      }
    })
  }

  @Emit('handleFilterChange')
  clearFilter(filter: FilterFields) {
    if (filter.type === 'price')
      this.selectedPriceOptions = this.selectedPriceOptions.filter(
        (opt) => opt.value !== filter.value
      )
    else if (filter.type === 'builder')
      this.selectedBuilderOptions = this.selectedBuilderOptions.filter(
        (opt) => opt.value !== filter.value
      )
    else if (filter.type === 'arch')
      this.selectedArchOptions = this.selectedArchOptions.filter(
        (opt) => opt.value !== filter.value
      )
    else if (filter.type === 'sort')
      this.selectedSortOption = this.selectedSortOption.filter(
        (opt) => opt.value !== filter.value
      )
    else if (filter.type === 'neighborhood')
      this.selectedNeighborhood = this.selectedNeighborhood.filter(
        (opt) => opt.value !== filter.value
      )
    else if (filter.type === 'property_type')
      this.selectedPropertyTypeOptions =
        this.selectedPropertyTypeOptions.filter(
          (opt) => opt.value !== filter.value
        )
    else if (filter.type === 'bedrooms') this.selectedBedRooms = null
    else if (filter.type === 'baths') this.selectedBaths = null
    else if (filter.type === 'garages') this.selectedGarages = null
    else if (filter.type === 'stories') this.selectedStories = null
    else if (filter.type === 'sqft_min') this.sqftMin = null
    else if (filter.type === 'sqft_max') this.sqftMax = null
    else if (filter.type === 'price_min') this.priceMin = null
    else if (filter.type === 'price_max') this.priceMax = null
    else if (filter.type === 'move_in_date') this.moveInDate = null
    else if (filter.type === 'condo_at') this.condoAt = null
    else {
      this.selectedCustomFilterOptions =
        this.selectedCustomFilterOptions.filter((opt) => {
          if (opt.value !== filter.value || opt.type !== filter.type) {
            return true
          } else return false
        })
    }
  }

  clearFilters() {
    this.selectedPriceOptions = []
    this.selectedBuilderOptions = []
    this.selectedArchOptions = []
    this.selectedBedRooms = null
    this.selectedBaths = null
    this.selectedGarages = null
    this.selectedStories = null
    this.selectedNeighborhood = []
    this.sqftMin = null
    this.sqftMax = null
    this.priceMin = null
    this.priceMax = null
    this.moveInDate = null
    this.selectedSortOption = []
    this.condoAt = null
    this.selectedPropertyTypeOptions = []

    this.selectedCustomFilterOptions = []
    this.mFilterChange(false)
  }

  mFilterChange(value: boolean): void {
    this.$emit('showMobileFilters', value)
  }

  @Watch('$route')
  clearOnRouteChange(
    route: { name: string; query: { preserve_filters: string } },
    oldRoute: { name: string }
  ): void {
    if (
      route.query.preserve_filters !== 'true' &&
      route.name !== oldRoute.name
    ) {
      this.clearFilters()
    }
  }

  getActiveRoute(): string {
    return this.$route.path.split('/').pop()
  }

  getFiltersVisibilitySettings(): any {
    return {
      price: this.isVisible('--ad-cm-filter-price'),
      bedrooms: this.isVisible('--ad-cm-filter-bedrooms'),
      builder: this.isVisible('--ad-cm-filter-builder'),
      sqft: this.isVisible('--ad-cm-filter-sqft'),
      architecture: this.isVisible('--ad-cm-filter-architecture'),
      available: this.isVisible('--ad-cm-filter-available'),
      neighborhood: this.isVisible('--ad-cm-filter-neighborhood'),
      floorplan: this.isVisible('----ad-rm-filter-floorplan'),
      propertyType: this.isVisible('--ad-cm-filter-property-type'),
    }
  }

  getPagesVisibilitySettings(): any {
    return {
      map: this.isVisible('--v3-cm-page-map'),
      plans: this.isVisible('--v3-cm-page-plans'),
      favorites: this.isVisible('--v3-cm-page-favorites'),
    }
  }

  onHomeViewClicked(): void {
    Events.pages('page_clicked', 'Explore Plans')
  }

  onMapViewClicked(): void {
    Events.pages('page_clicked', 'Map View')
  }

  onShareWithMeClicked(): void {
    Events.pages('page_clicked', 'Shared with Me')
  }

  onFavoritesClicked(): void {
    Events.pages('page_clicked', 'Favorites')
  }

  checkIsMobile() {
    this.isMobile = window.innerWidth <= 1024
  }

  getColor() {
    const color = this.getStyleVariableValue('--cm-sp-fav-fill')?.replace(/['"]+/g, '')
    if (color) return { color }
    return {}
  }

  getOutlineColor() {
    const color = this.getStyleVariableValue('--cm-sp-fav-outline')?.replace(/['"]+/g, '')
    if (color) return { color }
    return {}
  }

  get isMapPlansDisabled() {
    return (
      this.isConsumerMapPlansDisabled === 'true' ||
      this.isConsumerMapPlansDisabled === 'disable_plan_page'
    )
  }
}
